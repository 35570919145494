// @ts-nocheck
import cx from "classix";

import { Container } from "components/LandingPage/Container";
import Link from "next/link";
import { useState } from "react";

import analytics from "@/utils/analytics/analytics";

const plans = [
  {
    stripeId: "price_1OZBumJFWuBJJ6gcd7lFG8Ha",
    credits: "25 000",
    price: 9,
    id: "0000004JFGS2PKM95R06GG759D",
  },
  {
    stripeId: "price_1OZBuXJFWuBJJ6gcpgZHP3pC",
    credits: "50 000",
    price: 15,
    id: "0000004JFG1MEZRM2N538ZH3Q0",
  },
  {
    stripeId: "price_1OZBx5JFWuBJJ6gc5qEsEfBD",
    credits: "100 000",
    price: 25,
    id: "39d4aeda-6d47-4a01-8c52-16e380d3f905",
  },
  {
    stripeId: "price_1OZBvOJFWuBJJ6gc5kCK5cFB",
    credits: "250 000",
    price: 49,
    id: "0000004JFGGVPCTETPYHPMYFVH",
  },
  {
    stripeId: "price_1OZBvhJFWuBJJ6gcOJfxsMWg",
    credits: "500 000",
    price: 89,
    id: "0000004JFG9CBVG421FPHJFWTF",
  },
];

export function Pricing({
  inApp = false,
  onBuy,
}: { inApp?: boolean; onBuy?: (planId: string) => void } = {}) {
  const [selectedPlan, setSelectedPlan] = useState(plans[2]);

  function handleStoreSelectedPlan() {
    localStorage.setItem("selectedPlan", selectedPlan.stripeId);
  }
  return (
    <section id="pricing" aria-label="Pricing" className="mt-8 pb-32">
      <Container>
        {!inApp && (
          <div className=" flex flex-col items-center">
            <h2 className="gradient-text font-bold text-center text-4xl">
              No Subscription Fee.
            </h2>
            <p className="text-gray-400 max-w-4xl text-center mt-7 text-balance">
              Explore flexible pricing with our free tier: 500 credits to start.
              Pay-as-you-go seamlessly at 1 credit per API request—no
              subscription needed.
            </p>

            <Link
              href="/signup"
              className="text-purple mt-8 underline"
              onClick={() =>
                analytics.track("click_create_account", {
                  fromPricing: true,
                  selectedPlan,
                })
              }
            >
              Get started for free with 500 credits
            </Link>
          </div>
        )}

        <div className="flex justify-center mt-8">
          <div className="border-[1px] border-[#5E5176] bg-[#C8C8C8]/5 rounded-[20px] w-full max-w-5xl px-8 py-6">
            <p className="text-white text-2xl">Pay as you go</p>
            <div className="flex mt-8 lg:flex-row flex-col">
              <ul className="flex flex-col lg:w-1/2 sm:pr-8">
                {[...plans.slice(0, 3)].map((plan) => (
                  <PlanOption
                    isSelected={selectedPlan.stripeId === plan.stripeId}
                    key={plan.stripeId}
                    plan={plan}
                    onCheck={() => setSelectedPlan(plan)}
                  />
                ))}
              </ul>
              <ul className="flex flex-col lg:w-1/2 sm:pr-8">
                {[...plans.slice(3, 5)].map((plan) => (
                  <PlanOption
                    isSelected={selectedPlan.stripeId === plan.stripeId}
                    key={plan.stripeId}
                    plan={plan}
                    onCheck={() => setSelectedPlan(plan)}
                  />
                ))}
              </ul>
            </div>
            <div className="w-full h-[1px] bg-[#46414F] mt-8" />
            <div className="flex flex-col mt-8 items-center">
              <p className="text-white text-xl">All plan include</p>
              <p className="text-gray-50 text-sm mt-4 sm:mt-2 flex gap-1 sm:gap-4 flex-col items-center sm:flex-row">
                <span>Versioning</span>
                <span>•</span>
                <span>Analytics</span>
                <span>•</span>
                <span>Support</span>
              </p>

              {!inApp && (
                <Link
                  href="/signup"
                  onClick={handleStoreSelectedPlan}
                  className="bg-purple text-white px-8 py-2 rounded-3xl font-bold hover:bg-transparent border border-purple mt-8 sm:min-w-[325px] text-center"
                >
                  {`Get started with ${selectedPlan.credits} credits`}
                </Link>
              )}

              {inApp && (
                <button
                  onClick={() => onBuy(selectedPlan.id)}
                  className="bg-purple text-white px-8 py-2 rounded-3xl font-bold hover:bg-transparent border border-purple mt-8 sm:min-w-[325px] text-center"
                >{`Buy ${selectedPlan.credits} credits`}</button>
              )}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

function PlanOption({
  isSelected = false,
  plan,
  onCheck,
}: {
  isSelected: boolean;
  plan: typeof plans;
  onCheck: () => void;
}) {
  return (
    <li
      className={cx(
        "flex w-full items-center py-2 md:pl-4 pr-6 md:pr-8 rounded-xl justify-between border cursor-pointer",
        isSelected && "bg-[#202020] border-[#6F30FF]",
        !isSelected && "border-transparent"
      )}
      onClick={onCheck}
    >
      <div className="flex items-center py-2 px-4 rounded-xl">
        <input
          type="radio"
          id={plan.stripeId}
          name={plan.stripeId}
          value={plan.stripeId}
          checked={isSelected}
          onChange={onCheck}
          className={cx(
            "border-[#6F30FF] text-[#6F30FF] focus:ring-[#6F30FF] bg-transparent"
          )}
        />
        <span className="text-white font-bold text-sm md:text-xl ml-2">
          {`${plan.credits} credits`}
        </span>
      </div>
      <p className="text-white">
        <span className="font-bold md:text-xl">${plan.price}</span>{" "}
        <span className="text-gray-100 text-sm">usd</span>
      </p>
    </li>
  );
}
