// // @ts-nocheck
// import Link from "next/link";

// import { Logo } from "components/LandingPage/Logo";

// const navigation = {
//   solutions: [
//     { name: "Features", href: "/#features" },
//     { name: "Pricing", href: "/#pricing" },
//     // { name: "FAQ", href: "/#faq" },
//   ],
//   developer: [
//     {
//       name: "Documentation",
//       href: "https://docs.myjson.online/",
//     },
//   ],
//   // tools: [
//   //   { name: "Beautify", href: "/beautify" },
//   //   { name: "Storage", href: "/storage" },
//   // ],
//   legal: [
//     { name: "Privacy Policy", href: "/privacy-policy" },
//     { name: "Terms of Service", href: "/terms-of-service" },
//   ],
//   social: [
//     {
//       name: "Twitter",
//       href: "https://twitter.com/co_demers",
//       icon: (props) => (
//         <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
//           <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
//         </svg>
//       ),
//     },
//     // {
//     //   name: "GitHub",
//     //   href: "https://github.com/codemers",
//     //   icon: (props) => (
//     //     <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
//     //       <path
//     //         fillRule="evenodd"
//     //         d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
//     //         clipRule="evenodd"
//     //       />
//     //     </svg>
//     //   ),
//     // },
//   ],
// };

// export function Footer() {
//   return (
//     <footer className="" aria-labelledby="footer-heading">
//       <h2 id="footer-heading" className="sr-only">
//         Footer
//       </h2>
//       <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
//         <div className="lg:grid lg:grid-cols-3 lg:gap-8">
//           <div className="space-y-8 lg:col-span-1">
//             <div className="w-24">
//               <Logo dark className="mx-auto h-10 w-auto" />
//             </div>
//             <p className="text-gray-200 text-base">Json Storage as a service</p>
//             <div className="flex space-x-6">
//               {navigation.social.map((item) => (
//                 <a
//                   key={item.name}
//                   href={item.href}
//                   className="text-gray-200 hover:text-purple"
//                 >
//                   <span className="sr-only">{item.name}</span>
//                   <item.icon className="h-6 w-6" aria-hidden="true" />
//                 </a>
//               ))}
//             </div>
//           </div>
//           <div className="mt-12 grid grid-cols-1 gap-8 lg:mt-0 lg:col-span-2">
//             <div className="md:grid md:grid-cols-2 md:gap-8">
//               <div>
//                 <h3 className="text-sm font-semibold text-white tracking-wider uppercase">
//                   Solutions
//                 </h3>
//                 <ul role="list" className="mt-4 space-y-4">
//                   {navigation.solutions.map((item) => (
//                     <li key={item.name}>
//                       <Link legacyBehavior href={item.href}>
//                         <a className="text-base text-gray-200 hover:text-gray-400">
//                           {item.name}
//                         </a>
//                       </Link>
//                     </li>
//                   ))}
//                 </ul>
//               </div>
//               <div className="mt-12 md:mt-0">
//                 <h3 className="text-sm font-semibold text-white tracking-wider uppercase">
//                   Developer
//                 </h3>
//                 <ul role="list" className="mt-4 space-y-4">
//                   {navigation.developer.map((item) => (
//                     <li key={item.name}>
//                       <Link legacyBehavior href={item.href}>
//                         <a
//                           className="text-base text-gray-200 hover:text-gray-400"
//                           target="_blank"
//                         >
//                           {item.name}
//                         </a>
//                       </Link>
//                     </li>
//                   ))}
//                 </ul>
//               </div>
//             </div>
//             <div className="md:grid md:grid-cols-2 md:gap-8">
//               {/* <div>
//                 <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
//                   Tools
//                 </h3>
//                 <ul role="list" className="mt-4 space-y-4">
//                   {navigation.tools.map((item) => (
//                     <li key={item.name}>
//                       <Link legacyBehavior href={item.href}>
//                         <a className="text-base text-gray-500 hover:text-gray-900">
//                           {item.name}
//                         </a>
//                       </Link>
//                     </li>
//                   ))}
//                 </ul>
//               </div> */}
//               <div className="mt-12 md:mt-0">
//                 <h3 className="text-sm font-semibold text-white tracking-wider uppercase">
//                   Legal
//                 </h3>
//                 <ul role="list" className="mt-4 space-y-4">
//                   {navigation.legal.map((item) => (
//                     <li key={item.name}>
//                       <Link legacyBehavior href={item.href}>
//                         <a className="text-base text-gray-200 hover:text-gray-400">
//                           {item.name}
//                         </a>
//                       </Link>
//                     </li>
//                   ))}
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="mt-12 border-t border-gray-600 pt-8">
//           <p className="text-base text-gray-300 xl:text-center">
//             &copy; 2024 myJson. All rights reserved.
//           </p>
//         </div>
//       </div>
//     </footer>
//   );
// }

const navigation = {
  main: [
    {
      name: "Developer documentation",
      href: "https://docs.myjson.online/",
      target: "_blank",
    },
    { name: "Privacy policy", href: "/privacy-policy" },
    { name: "Terms of service", href: "/terms-of-service" },
  ],
  social: [
    {
      name: "X",
      href: "https://twitter.com/co_demers",
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
        </svg>
      ),
    },
  ],
};

export function Footer() {
  return (
    <footer className="bg-[#202021]">
      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
        <nav
          className="-mb-6 columns-1 sm:flex sm:justify-center sm:space-x-12"
          aria-label="Footer"
        >
          {navigation.main.map((item) => (
            <div key={item.name} className="pb-6">
              <a
                href={item.href}
                className="text-sm leading-6 text-gray-300 hover:text-purple"
                target={item.target}
              >
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <div className="mt-10 flex justify-center space-x-10">
          {navigation.social.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-gray-300 hover:text-purple"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
        <p className="mt-10 text-center text-xs leading-5 text-gray-400">
          &copy; 2024 myJson.online. All rights reserved.
        </p>
      </div>
    </footer>
  );
}
