// @ts-nocheck

import { Container } from "components/LandingPage/Container";

import cx from "classix";
import { Glow, GlowCapture } from "@codaworks/react-glow";
import {
  ChartBarIcon,
  CodeBracketSquareIcon,
  LockClosedIcon,
  Square2StackIcon,
} from "@heroicons/react/24/outline";

export function PrimaryFeatures() {
  return (
    <section id="features" className="py-20 sm:py-24 z-50 relative features">
      <Container className="max-w-7xl">
        <div className="flex justify-center">
          <h2 className="gradient-text font-bold text-4xl text-center">
            Features that will make you love myJson.
          </h2>
        </div>

        <GlowCapture>
          <div className="mt-16 grid grid-cols-1 md:grid-cols-5 gap-y-4 md:gap-8">
            <FeatureCard
              className="col-span-3"
              title="Rest API"
              description="Easily store your JSON data and perform all CRUD operations —Create, Read, Update, and Delete— with remarkable ease and flexibility"
              icon={<CodeBracketSquareIcon />}
            />
            <FeatureCard
              className="col-span-2"
              title="Security"
              description="Private collections, allow data protection with API keys."
              icon={<LockClosedIcon />}
            />
          </div>
          <div className="mt-4 md:mt-8 grid grid-cols-1 md:grid-cols-5 gap-y-4 md:gap-8">
            <FeatureCard
              className="col-span-2"
              title="Versioning"
              description="
              Every update generates a record version, enabling the option to revert to a prior state when necessary."
              icon={<Square2StackIcon />}
            />
            <FeatureCard
              className="col-span-3"
              title="Analytics"
              description="
              Gain valuable insights by accessing real-time statistics that display the number of requests you've made and the volume of data you've stored, allowing you to have a comprehensive view of your usage at any given moment."
              icon={<ChartBarIcon />}
            />
          </div>
        </GlowCapture>
      </Container>
    </section>
  );
}

function FeatureCard({
  title,
  description,
  className,
  icon,
}: {
  title: string;
  description: string;
  className?: string;
  icon: React.ForwardRefExoticComponent<React.SVGProps<SVGSVGElement>>;
}) {
  return (
    <div
      className={cx(
        "border-[1px] border-[#5E5176] bg-[#C8C8C8]/5 rounded-[20px] w-full",
        className
      )}
    >
      <Glow className="">
        <div className="glow:text-glow/50 glow:bg-purple/10 px-16 py-12 h-full">
          <div className="flex gap-2 items-center">
            <div className="w-8 md:w-9 text-gray-300">{icon}</div>
            <span className="text-2xl md:text-4xl text-white font-medium">
              {title}
            </span>
          </div>
          <p className="text-[#C4C4C4] mt-4 h-full">{description}</p>
        </div>
      </Glow>
    </div>
  );
}
