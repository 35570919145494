/* eslint-disable @next/next/no-img-element */
export function Logo(props: { dark: boolean }) {
  if (props.dark) {
    return (
      <img
        src="/logo-white.svg"
        alt="Back to home"
        className="w-full h-[2rem]"
      />
    );
  }
  return <img src="/logo.svg" alt="Back to home" className="w-full h-[2rem]" />;
}
