// @ts-nocheck

import { Container } from "components/LandingPage/Container";

import {
  AcademicCapIcon,
  BadgeCheckIcon,
  CashIcon,
  ClockIcon,
  ReceiptRefundIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import cx from "classix";
import { Glow, GlowCapture } from "@codaworks/react-glow";

const useCases = [
  {
    name: "Prototyping",
    description:
      "Quickly develop your new application and add persistence without worrying about the database.",
    bgColor: "bg-primary-500",
  },
  {
    name: "CMS",
    description: "Build a website and use myJson to store your content.",
    bgColor: "bg-primary-500",
  },
  {
    name: "IoT",
    description: "Allow your IoT device to read and create data.",
    bgColor: "bg-secondary-500",
  },
  {
    name: "Dashboard",
    description:
      "Build a web application dashboard and store your data in myJson.",
    bgColor: "bg-secondary-500",
  },
];

export function UseCases() {
  return (
    <section id="use-cases" className="">
      <Container className="max-w-7xl">
        <div className="flex justify-center">
          <h2 className="gradient-text font-bold text-4xl">Use Cases</h2>
        </div>
        <div className="py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-8 overflow-hidden lg:mx-0 lg:max-w-none lg:grid-cols-4">
              {useCases.map((uc) => (
                <div
                  key={uc.name}
                  className="p-4 bg-[#C8C8C8]/5 rounded-[20px] border-[#5E5176] border"
                >
                  <p className="text-lg font-semibold leading-8 tracking-tight text-gray-100">
                    {uc.name}
                  </p>
                  <p className="mt-1 text-base text-gray-300">
                    {uc.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

function UseCaseCard({
  title,
  description,
  className,
}: {
  title: string;
  description: string;
  className?: string;
}) {
  return (
    <div
      className={cx(
        "border-[1px] border-[#5E5176] bg-[#C8C8C8]/5 rounded-[20px] w-full",
        className
      )}
    >
      <Glow className="">
        <div className="glow:text-glow/50 glow:bg-purple/10 px-16 py-12 h-full">
          <span className="text-4xl text-white">{title}</span>
          <p className="text-[#C4C4C4] mt-4 h-full">{description}</p>
        </div>
      </Glow>
    </div>
  );
}
