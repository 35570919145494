import { Container } from "@/components/LandingPage/Container";
import analytics from "@/utils/analytics/analytics";

import Link from "next/link";

export function Hero() {
  return (
    <Container className="pb-16 sm:pb-32 text-center pt-32 md:pt-56">
      <h1 className="mx-auto max-w-[50rem] font-display text-4xl sm:text-6xl md:text-8xl font-bold gradient-text">
        JSON storage as a service.
      </h1>
      <p className="mx-auto mt-6 max-w-lg text-sm sm:text-lg font-medium text-[#FAFAFA]">
        A RESTful API for flexible storage of JSON data. Allow developers to
        focus more on app development than on infrastructure management.
      </p>
      <div className="mt-10 flex justify-center gap-x-6">
        <Link
          href="/signup"
          className="bg-purple text-white px-8 py-2 rounded-3xl font-bold hover:bg-transparent border border-purple"
          onClick={() => analytics.track("click_create_account")}
        >
          Create account
        </Link>
      </div>
    </Container>
  );
}
