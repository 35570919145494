import { Fragment, useState } from "react";
import Link from "next/link";
import { Popover, Transition } from "@headlessui/react";
import cx from "classix";

import { Logo } from "@/components/LandingPage/Logo";
import analytics from "@/utils/analytics/analytics";

function MobileNavLink({
  href,
  children,
  onClick,
  className,
}: {
  href: string;
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
}) {
  return (
    <Popover.Button
      as={Link}
      href={href}
      className={cx("block w-full p-2", className)}
      onClick={onClick}
    >
      {children}
    </Popover.Button>
  );
}

function MobileNavIcon({ open }: { open: boolean }) {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-200"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
    >
      {/* Hamburger Menu Icon */}
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={cx("origin-center transition", open && "scale-90 opacity-0")}
      />
      {/* X Icon */}
      <path
        d="M2 2L12 12M12 2L2 12"
        className={cx(
          "origin-center transition",
          !open && "scale-90 opacity-0 stroke-slate-200"
        )}
      />
    </svg>
  );
}

function MobileNavigation() {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <Popover>
      <Popover.Button
        className="relative z-10 flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none"
        aria-label="Toggle Navigation"
        onClick={() => setMenuOpen(!menuOpen)}
      >
        {() => <MobileNavIcon open={menuOpen} />}
      </Popover.Button>
      {menuOpen && (
        <Transition.Root>
          <Transition.Child
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-150 ease-in"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              as="div"
              className="absolute inset-x-0 flex origin-top flex-col bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5 top-0 pt-7"
            >
              <MobileNavLink href="/#features">Features</MobileNavLink>
              <MobileNavLink href="/#pricing">Pricing</MobileNavLink>
              {/* <MobileNavLink href="/changelog">Changelog</MobileNavLink> */}
              <hr className="m-2 border-slate-300/40" />
              <MobileNavLink
                onClick={() => analytics.track("click_login")}
                href="/login"
              >
                Sign In
              </MobileNavLink>
              <MobileNavLink
                onClick={() => analytics.track("click_create_account")}
                href="/signup"
                className="bg-purple text-white px-4 py-2 rounded-3xl font-bold hover:bg-transparent border border-purple text-center mt-8"
              >
                Sign Up
              </MobileNavLink>
            </Popover.Panel>
          </Transition.Child>
        </Transition.Root>
      )}
    </Popover>
  );
}

function NavLink({
  href,
  children,
  onClick,
}: {
  href: string;
  children: React.ReactNode;
  onClick?: () => void;
}) {
  return (
    <Link
      href={href}
      className="text-white text-sm font-bold hover:text-purple"
      onClick={onClick}
    >
      {children}
    </Link>
  );
}

export function Header(props: { dark: boolean }) {
  const { dark } = props;

  return (
    <header className="flex justify-center absolute w-full z-50">
      <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
      <meta property="og:title" content="myjson.online" />
      <meta property="og:site_name" content="myjson.online" />
      <meta property="og:url" content="myjson.online" />
      <meta
        property="og:description"
        content="A RESTful API for flexible storage of JSON data. Allow developers to focus more on app development than on infrastructure management."
      />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="/og-image.png" />
      <div className="flex items-center py-8 w-full max-w-7xl px-8 justify-between">
        <div className="flex items-center">
          <Link href="/">
            <Logo dark={dark} />
          </Link>
          <nav className="hidden ml-16 mt-1 md:flex">
            <div className="flex gap-10">
              <NavLink href="/#features">Features</NavLink>
              <NavLink href="/#pricing">Pricing</NavLink>
              {/* <NavLink href="/changelog">Changelog</NavLink> */}
            </div>
          </nav>
        </div>
        <div className="hidden md:flex items-center gap-4">
          <NavLink href="/login" onClick={() => analytics.track("click_login")}>
            Sign In
          </NavLink>
          <Link
            href="/signup"
            className="bg-purple text-white px-4 py-2 rounded-3xl font-bold hover:bg-transparent border border-purple"
            onClick={() => analytics.track("click_create_account")}
          >
            Sign Up
          </Link>
        </div>
        <div className="md:hidden">
          <MobileNavigation />
        </div>
      </div>
    </header>
  );
}
